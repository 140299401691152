// Here you can add other styles
.apple-auth-btn {
    width: 300px;
}

.react-apple-signin-auth-btn {
    width: 190px;
    height: 43px;
    margin-top: 15px;
}

.budgetInputAlign {
    margin-top: 20px;
    margin-bottom: 20px;
   
}

.radioDiv {
    display: inline-block;
}

.radioDiv:not(:first-child) {
    margin-left: 20px;
}

.radioDiv label {
    margin-bottom: 0px;
    margin-left: 10px;
}

.txt-label {
    margin-right: 10px;
}

.var-month {
    text-align: center;
}
.var-month span {
    display: block;
}

.var-month:not(:first-child) {
    margin-left: 20px;
}

#new-chart canvas {
    height: 310px !important;
}